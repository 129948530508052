;(() => {
    window.addEventListener('load', () => {
        const iboxesContent = document.querySelectorAll('.ibox-content')

        iboxesContent.forEach(iboxContent => {
            const tables = iboxContent.querySelectorAll('.table')

            tables.forEach(table => {
                const wrapper = document.createElement('div')
                wrap(table, wrapper)
                mouseDrag(wrapper)
            })
        })
    })

    function wrap(table, wrapper) {
        const parent = table.parentNode

        wrapper.classList.add('scroll-table-wrapper')
        parent.insertBefore(wrapper, table)
        wrapper.append(table)
    }

    function mouseDrag(wrapper, dragSpeed = 1.5) {
        let isDown = false
        let startX = 0
        let scrollLeft = 0

        wrapper.addEventListener('mousedown', e => {
            isDown = true
            wrapper.classList.add('scroll-table-wrapper_drag')
            startX = e.pageX - wrapper.offsetLeft
            scrollLeft = wrapper.scrollLeft
        })

        wrapper.addEventListener('mouseleave', () => {
            isDown = false
            wrapper.classList.remove('scroll-table-wrapper_drag')
        })

        wrapper.addEventListener('mouseup', () => {
            isDown = false
            wrapper.classList.remove('scroll-table-wrapper_drag')
        })

        wrapper.addEventListener('mousemove', e => {
            if (!isDown) return
            e.preventDefault()
            const x = e.pageX - wrapper.offsetLeft
            const walk = (x - startX) * dragSpeed
            wrapper.scrollLeft = scrollLeft - walk
        })
    }
})()
