;(() => {
    const togglers = document.querySelectorAll('.checkbox-toggler')
    const contentHideClass = 'checkbox-toggler__content_hidden'
    togglers.forEach(toggler => {
        const trigger = toggler.querySelector('.checkbox-toggler__trigger')
        const content = toggler.querySelector('.checkbox-toggler__content')
        if (trigger) {
            updateState(trigger.checked, content)
            trigger.addEventListener('change', e => updateState(e.target.checked, content))
        }
    })

    function updateState(state, content) {
        if (state) {
            content.classList.remove(contentHideClass)
        } else {
            content.classList.add(contentHideClass)
        }
    }
})()
