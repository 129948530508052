;(() => {
    const infWatermarkInput = document.querySelector('#informer_image')
    const infWatermarkBlock = document.querySelector('#informer-watermark-block')
    const infPreview = document.querySelector('#informer-preview')

    if (infWatermarkInput && infWatermarkBlock) {
        infWatermarkInput.addEventListener('change', e => {
            if (e.target.files[0]) {
                infWatermarkBlock.style.display = 'flex'
                if (infPreview) {
                    infPreview.style.display = 'none'
                }
            }
        })
    }
})()
;(() => {
    const previewWatermarkInput = document.querySelector('#preview_image')
    const previewWatermarkBlock = document.querySelector('#preview-watermark-block')
    const previewImage = document.querySelector('#preview-image')

    if (previewWatermarkInput && previewWatermarkBlock) {
        previewWatermarkInput.addEventListener('change', e => {
            if (e.target.files[0]) {
                previewWatermarkBlock.style.display = 'flex'
                if (previewImage) {
                    previewImage.style.display = 'none'
                }
            }
        })
    }
})()
