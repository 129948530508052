tinymce.PluginManager.add('GigaInsta', function (editor, url) {
    const openDialog = () => {
        return editor.windowManager.open({
            title: 'Інстаграм пост',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'input',
                        name: 'source',
                        label: 'Інстаграм посилання'
                    },
                    {
                        type: 'input',
                        name: 'offset',
                        label: 'Офсет для висоти з сайту https://www.picodash.com/instagram/embed',
                        require: true
                    }
                ]
            },
            buttons: [
                {
                    type: 'cancel',
                    text: 'Відмінити'
                },
                {
                    type: 'submit',
                    text: 'Добавити',
                    primary: true
                }
            ],

            onSubmit(api) {
                const data = api.getData()

                let { source, offset } = data

                if (!source.length || !offset.length) return

                let valid = false

                const starts = ['https://www.instagram.com/p/', 'https://www.instagram.com/tv/']

                for (const startString of starts) {
                    if (source.includes(startString)) {
                        source = source.replace(startString, '')
                        valid = true
                        break
                    }
                }

                if (!valid) return

                const endString = source.indexOf('/')
                if (endString >= 0) {
                    source = source.slice(0, endString)
                }

                editor.insertContent(`[instagram]${source.trim()} ${offset.trim()}[/instagram]`)

                api.close()
            }
        })
    }

    editor.ui.registry.addButton('GigaInsta', {
        text: 'Інстаграм',
        onAction() {
            openDialog()
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaInsta',
                url: ''
            }
        }
    }
})
