tinymce.PluginManager.add('GigaShort', function (editor, url) {
    editor.ui.registry.addButton('GigaShort', {
        text: 'Кор. текст',
        onAction() {
            editor.focus()

            const text = editor.selection.getContent({ format: 'html' })

            if (text && text.length > 0) {
                editor.execCommand(
                    'mceInsertContent',
                    false,
                    `<br />[shortText]<p>${text}</p>[/shortText]<br />`
                )
            }
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaShort',
                url: ''
            }
        }
    }
})
