import Cropper from 'cropperjs'

const croppersWrapper = document.querySelectorAll('.cropper')

if (croppersWrapper) {
    croppersWrapper.forEach(cropperWrapper => {
        const image = cropperWrapper.querySelector('.img-container-img')
        const imagePrev = cropperWrapper.querySelector('.img-preview')
        const btnCrop = cropperWrapper.querySelector('.btn-crop')
        const btnRatio = cropperWrapper.querySelectorAll('.aspect-ratio button')
        const attrWire = cropperWrapper.querySelector('.live-img')
        const buttons = cropperWrapper.querySelectorAll('button')
        const aspectButtons = cropperWrapper.querySelectorAll('button[data-photo-type]')
        const imageTypeInputID = cropperWrapper.dataset.cropperImageTypeInputId

        // console.log(imageTypeInputID)

        const defaultAspect = +cropperWrapper.dataset.defaultAspect || 16 / 9
        // console.log(defaultAspect)

        const options = {
            viewMode: 1,
            zoomable: false,
            aspectRatio: defaultAspect
        }

        let cropper = new Cropper(image, options)

        buttons.forEach(button => {
            button.addEventListener('click', e => e.preventDefault())
        })

        aspectButtons.forEach(button => {
            button.addEventListener('click', e => {
                const input = document.querySelector(`#${imageTypeInputID}`)
                input.value = button.dataset.photoType
                // console.dir(input)
            })
        })

        cropper.getCroppedCanvas({
            width: 160,
            height: 90,
            minWidth: 400,
            minHeight: 400,
            maxWidth: 400,
            maxHeight: 400
        })

        btnRatio.forEach(btn => {
            btn.addEventListener('click', ({ target }) => {
                let option = target.dataset.ratio
                cropper.destroy()
                options.aspectRatio = option
                cropper = new Cropper(image, options)

                btnRatio.forEach(btn => btn.classList.remove('active'))
                target.classList.add('active')
            })
        })

        btnCrop.addEventListener('click', () => {
            let imgUrl = cropper.getCroppedCanvas().toDataURL()
            let img = document.createElement('img')
            img.setAttribute('src', imgUrl)
            imagePrev.innerHTML = ''
            imagePrev.append(img)

            // console.log(imgUrl)

            fetch(imgUrl)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], 'croppedImage', { type: 'image/png' })
                    // console.log(file)

                    const formData = new FormData()

                    // get news id
                    const idDiv = document.querySelector('[data-news-id]') || null
                    formData.append('news_id', idDiv.dataset.newsId)

                    // get name image var
                    const imageVarName = cropperWrapper.dataset.imageVarName
                    formData.append(imageVarName, file)

                    // get name watermark var
                    const watermarkInputId = cropperWrapper.dataset.watermarkInputId
                    const watermarkInput = $(`#${watermarkInputId}`)[0]
                    formData.append(watermarkInputId, watermarkInput.checked)

                    // Phototype
                    const input = document.querySelector(`#${imageTypeInputID}`)
                    if (input) {
                        formData.append('type', input.value) // wide, quare
                        console.warn('type', input.value)
                    }

                    // for (var pair of formData.entries()) {
                    //     console.log(pair[0], pair[1])
                    // }

                    // send File to server
                    $.ajax({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        url: '/api/cropper',
                        method: 'post',
                        dataType: 'json',
                        data: formData,
                        cache: false,
                        contentType: false,
                        processData: false,
                        complete: function () {
                            // console.log('complete')
                        },
                        success: function (response) {
                            // console.log(response)
                            const { type, preview_image, informer_image } = response
                            console.log(response)
                            if (type) {
                                const previewBlock = document.querySelector(
                                    `#cropper-preiew-block-${type}`
                                )
                                if (previewBlock) {
                                    previewBlock.style.display = 'block'
                                    const image = previewBlock.querySelector('img')
                                    console.log('image', image)
                                    if (image) {
                                        image.src = preview_image || informer_image
                                    }
                                }
                            }
                        },
                        error: function (err) {
                            // console.error(err)
                        }
                    })
                })

            // Livewire.find([attrWire.getAttribute('wire:id')]).upload(
            //     cropperWrapper.dataset.live,
            //     dataURLtoFile(imgUrl)
            // )
        })

        const inputImage = cropperWrapper.querySelector('.input-image')
        let uploadedImageType = 'image/jpeg'
        let uploadedImageName = 'cropped.jpg'
        let uploadedImageURL

        inputImage.onchange = function () {
            let files = this.files
            let file

            if (files && files.length) {
                file = files[0]

                const reader = new FileReader()
                reader.addEventListener('load', event => {
                    let img = document.createElement('img')
                    img.setAttribute('src', event.target.result)
                    imagePrev.innerHTML = ''
                    imagePrev.append(img)
                })
                reader.readAsDataURL(file)

                if (/^image\/\w+/.test(file.type)) {
                    uploadedImageType = file.type
                    uploadedImageName = file.name

                    if (uploadedImageURL) {
                        URL.revokeObjectURL(uploadedImageURL)
                    }

                    image.src = uploadedImageURL = URL.createObjectURL(file)

                    if (cropper) {
                        cropper.destroy()
                    }

                    cropper = new Cropper(image, options)
                    inputImage.value = null
                } else {
                    window.alert('Please choose an image file.')
                }
            }
        }
    })

    function dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], filename, { type: mime })
    }

    const urlToObject = async () => {
        const response = await fetch(image)
        // here image is url/location of image
        const blob = await response.blob()
        const file = new File([blob], 'image.jpg', { type: blob.type })
        // console.log(file)
    }
}
