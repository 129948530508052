tinymce.PluginManager.add('GigaYotube', function (editor, url) {
    const openDialog = () => {
        return editor.windowManager.open({
            title: 'YouTube Відео',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'input',
                        name: 'source',
                        label: 'На Youtube видео нажмите "Поделиться",<br/>нажмите на кнопку "Встроить", скопируйте код и вставте сюда<br />'
                    }
                ]
            },
            buttons: [
                {
                    type: 'cancel',
                    text: 'Отмена'
                },
                {
                    type: 'submit',
                    text: 'Добавить',
                    primary: true
                }
            ],

            onSubmit(api) {
                const data = api.getData()
                const { source } = data
                if (source.length) {
                    const srcPosStartString = 'src="'
                    const srcPosStart = source.indexOf(srcPosStartString)
                    let srcPosEnd = source.indexOf('?si=', srcPosStart + srcPosStartString.length)
                    if (srcPosEnd == -1) {
                        srcPosEnd = source.indexOf('"', srcPosStart + srcPosStartString.length)
                    }
                    const src = source.slice(srcPosStart + srcPosStartString.length, srcPosEnd)

                    if (src.length > 5) {
                        editor.insertContent(`[youtube]${src}[/youtube]`)
                    }

                    api.close()
                }
            }
        })
    }

    editor.ui.registry.addButton('GigaYotube', {
        text: 'YouTube Відео',
        onAction() {
            openDialog()
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaYotube',
                url: ''
            }
        }
    }
})
