tinymce.PluginManager.add('GigaFacebookPost', function (editor, url) {
    const openDialog = () => {
        return editor.windowManager.open({
            title: 'Facebook пост',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'input',
                        name: 'source',
                        label: 'Facebook пост iframe<br />'
                    }
                ]
            },
            buttons: [
                {
                    type: 'cancel',
                    text: 'Відмінити'
                },
                {
                    type: 'submit',
                    text: 'Добавити',
                    primary: true
                }
            ],

            onSubmit(api) {
                const data = api.getData()

                const { source } = data

                const iframeStart = '<iframe src="'
                // const iframeStart = '<iframe src="https://www.facebook.com/plugins/post.php?href='
                const heightStart = 'height="'

                if (!source.includes(iframeStart) || !source.includes(heightStart)) return;

                const heightStartIndex = source.indexOf(heightStart) + heightStart.length
                const heightEndEndex = source.indexOf('"', heightStartIndex)
                const height = parseInt(source.slice(heightStartIndex , heightEndEndex))

                let link = source.replace(iframeStart, '')
                let linkEndIndex = link.indexOf('"')
                if (linkEndIndex < 0) {
                    linkEndIndex = link.indexOf("'")
                }

                if (linkEndIndex < 0) return;

                link = link.slice(0, linkEndIndex)

                editor.insertContent(`[facebookPost]${link} ${height}[/facebookPost]`)

                api.close()
            }
        })
    }

    editor.ui.registry.addButton('GigaFacebookPost', {
        text: 'Facebook пост',
        onAction() {
            openDialog()
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaFacebookPost',
                url: ''
            }
        }
    }
})
