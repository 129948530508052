tinymce.PluginManager.add('GigaTwitter', function (editor, url) {
    const openDialog = () => {
        return editor.windowManager.open({
            title: 'Твіттер пост',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'input',
                        name: 'source',
                        label: 'Твіттер посилання'
                    }
                ]
            },
            buttons: [
                {
                    type: 'cancel',
                    text: 'Відмінити'
                },
                {
                    type: 'submit',
                    text: 'Добавити',
                    primary: true
                }
            ],

            onSubmit(api) {
                const data = api.getData()

                let { source } = data

                if (!source.length) return

                let valid = false

                const starts = ['https://twitter.com/']

                for (const startString of starts) {
                    if (source.includes(startString)) {
                        source = source.replace(startString, '')
                        valid = true
                        break
                    }
                }

                const delimiter = '/status/'
                const parts = source.split(delimiter)

                if (!valid) return

                const endString = parts[1].indexOf('/')
                if (endString >= 0) {
                    parts[1] = parts[1].slice(0, endString)
                }

                editor.insertContent(`[twitter]${parts[0].trim()} ${parts[1].trim()}[/twitter]`)

                api.close()
            }
        })
    }

    editor.ui.registry.addButton('GigaTwitter', {
        text: 'Твіттер',
        onAction() {
            openDialog()
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaTwitter',
                url: ''
            }
        }
    }
})
