const { default: axios } = require('axios')

tinymce.PluginManager.add('GigaGallery', function (editor, url) {
    const openDialog = () => {
        return editor.windowManager.open({
            title: 'Додати фотографії до галереї',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'urlinput',
                        name: 'photos',
                        filetype: 'image',
                        label: 'Джерело',
                        disabled: false
                    },
                    {
                        type: 'input',
                        name: 'label',
                        label: 'Текст під галерею'
                    },
                    {
                        type: 'checkbox',
                        name: 'watermark',
                        label: 'Додати водяний знак на фото'
                    }
                ]
            },
            buttons: [
                {
                    type: 'cancel',
                    text: 'Скасувати'
                },
                {
                    type: 'submit',
                    text: 'Додати',
                    primary: true
                }
            ],
            onSubmit(api) {
                const data = api.getData()
                const { watermark, photos, label } = data
                if (photos.value) {
                    const formData = new FormData(document.getElementById('tinymce-photos-form'))
                    const idDiv = document.querySelector('[data-news-id]')
                    const newsId = idDiv.dataset.newsId
                    console.log('idNews', newsId)

                    formData.append('watermark', watermark)
                    formData.append('news_id', newsId)
                    formData.append('label', label)

                    window.CNS.fns.showLoaderPhotos()

                    $.ajax({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        url: '/api/gallery',
                        method: 'post',
                        dataType: 'json',
                        data: formData,
                        cache: false,
                        contentType: false,
                        processData: false,
                        complete: function () {
                            api.close()

                            window.CNS.fns.hideLoaderPhotos()
                        },
                        success: function (response) {
                            // console.log('res', response)
                            const images = response.data
                            const imagesArray = []

                            for (const key in images) {
                                if (images.hasOwnProperty(key)) {
                                    imagesArray.push(images[key].image)
                                }
                            }

                            editor.insertContent(`<br>[gallery]${response.gallery}[/gallery]<br>`)

                            Livewire.emit('update-images')

                            api.close()

                            window.CNS.fns.hideLoaderPhotos()
                        },
                        error: function (err) {
                            api.close()

                            window.CNS.fns.hideLoaderPhotos()
                        }
                    })

                    api.close()
                }
            }
        })
    }

    editor.ui.registry.addButton('GigaGallery', {
        text: 'Галерея',
        onAction() {
            openDialog()
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaGallery',
                url: ''
            }
        }
    }
})
