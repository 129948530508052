;(() => {
    window.addEventListener('load', () => {
        Livewire.on('initSelectTags', (text, id) => {
            var newOption = new Option(text, id, false, false)
            $('#select-tags').append(newOption).trigger('change')
            $('#input-tags').val('')
            $arr = $('#select-tags').val()
            $item = $arr.find(element => element == id.toString())
            if ($item) {
            } else {
                $arr.push(id.toString())
                $('#select-tags').val($arr)
                $('#select-tags').trigger('change')
            }
        })
    })
})()
