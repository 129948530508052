;(CNS => {
    const fullscreenLoaderShownClass = 'fullscreen-loader_shown'

    CNS.fns.showLoaderPhotos = function () {
        const loader = document.querySelector('#fullscreen-loader-photos')
        if (loader) {
            loader.classList.add(fullscreenLoaderShownClass)
        }
    }

    CNS.fns.hideLoaderPhotos = function () {
        const loader = document.querySelector('#fullscreen-loader-photos')
        if (loader) {
            loader.classList.remove(fullscreenLoaderShownClass)
        }
    }
})(window.CNS)
