;(() => {
    const inputs = document.querySelectorAll('.js-input-quotes')

    Array.from(inputs).forEach(input => {
        let shouldSetOpenQuote = true

        input.addEventListener('keypress', e => {
            if (e.key === '"') {
                e.preventDefault()

                const cursorPosition = e.target.selectionStart
                const inputValue = input.value
                const first = inputValue.slice(0, cursorPosition)
                const second = inputValue.slice(cursorPosition)

                let quote = shouldSetOpenQuote ? '«' : '»'
                shouldSetOpenQuote = !shouldSetOpenQuote
                const result = `${first}${quote}${second}`
                input.value = result

                input.focus()
                input.setSelectionRange(cursorPosition + 1, cursorPosition + 1)
            }
        })
    })
})()
