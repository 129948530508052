tinymce.PluginManager.add('GigaContentTitle', function (editor, url) {
    editor.ui.registry.addButton('GigaContentTitle', {
        text: 'Заголовок змісту',
        onAction() {
            editor.focus()

            const text = editor.selection.getContent({ format: 'html' })

            if (text && text.length > 0) {
                editor.execCommand(
                    'mceInsertContent',
                    false,
                    `[contentTitle]${text}</p>[/contentTitle]`
                )
            }
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaContentTitle',
                url: ''
            }
        }
    }
})
