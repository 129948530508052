tinymce.PluginManager.add('GigaTelegram', function (editor, url) {
    const openDialog = () => {
        return editor.windowManager.open({
            title: 'Телеграм посилання',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'input',
                        name: 'source',
                        label: 'Телеграм посилання в вигляді https://t.me/xxx<br />'
                    }
                ]
            },
            buttons: [
                {
                    type: 'cancel',
                    text: 'Відмінити'
                },
                {
                    type: 'submit',
                    text: 'Добавити',
                    primary: true
                }
            ],

            onSubmit(api) {
                const data = api.getData()

                const { source } = data

                const protocol = 'https://t.me/'
                if (source.includes(protocol)) {
                    const link = source.replace(protocol, '')

                    if (link.length > 0) {
                        editor.insertContent(`[telegram]${link}[/telegram]`)

                        api.close()
                    }
                }
            }
        })
    }

    editor.ui.registry.addButton('GigaTelegram', {
        text: 'Телеграм',
        onAction() {
            openDialog()
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaTelegram',
                url: ''
            }
        }
    }
})
