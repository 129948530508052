require('./bootstrap')
require('./cns')
require('./components/loaders')
require('./components/datatables.min')
require('./components/dataTables.bootstrap4.min')
require('./components/main')
require('./components/info')
require('./components/other')
require('./components/inputMask')
require('./components/tinymce-custom-plugins/gigaQuote')
require('./components/tinymce-custom-plugins/gigaPhotos')
require('./components/tinymce-custom-plugins/gigaGallery')
require('./components/tinymce-custom-plugins/gigaYoutube')
require('./components/tinymce-custom-plugins/gigaAnchor')
require('./components/tinymce-custom-plugins/gigaContentTitle')
require('./components/tinymce-custom-plugins/gigaShort')
require('./components/tinymce-custom-plugins/gigaTelegram')
require('./components/tinymce-custom-plugins/gigaInsta')
require('./components/tinymce-custom-plugins/gigaTwitter')
require('./components/tinymce-custom-plugins/gigaFacebookVideo')
require('./components/tinymce-custom-plugins/gigaFacebookPost')
require('./components/tinymce-custom-plugins/gigaLinks')
require('./components/tiny')
require('./components/scroll-table')
require('./components/checkbox-toggler')
require('./components/watermarks')
require('./components/cropper')
require('./components/trigger-resize')
require('./components/selectTags')
require('./components/inputQuotes')
