require('metismenu')
require('select2')

import swal from 'sweetalert'

var sideMenu = $('#side-menu').metisMenu({
    subMenu: '.nav'
})

sideMenu.on('show.metisMenu', function (e) {})

$('.admin_img_preview_span').on('click', function () {
    if (confirm('are you sure?')) {
        $.post($(this).data('url'), {
            file_to_be_deleted: $(this).data('image'),
            id: $(this).data('id'),
            _token: $('[name="_token"]').val()
        })
        $(this).parent().remove()
    }
})

$('.item_delete').on('click', function () {
    if (
        confirm('Видалення категорії тягне за собою видалення послуг, що належать їй. Продовжити?')
    ) {
        window.location.href = $(this).data('href')
    }
})

$('.select-custom').select2({
    minimumResultsForSearch: Infinity
})

$('[data-checkin]').on('click', function () {
    let position = $(this).data('position')
    if (position || position === 0) {
        $('.ajax-gallery').data('position', position)
    }
})

$('[data-checkout]').on('click', function () {
    let activeCLass = $(this).parent()
    if ($(this).prop('checked') === true) {
        activeCLass.addClass('active').siblings().removeClass('active')
    } else {
        activeCLass.removeClass('active')
        $('[data-checkout]:checked').prop('checked', false)
    }
})

$('.ajax-gallery').on('click', function () {
    let url = $('#GridGallery').data('href')
    let position = $('.ajax-gallery').data('position')
    let idItem = $('[data-checkout]:checked').val()
    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        type: 'POST',
        url: url,
        data: 'file_id=' + idItem + '&position=' + position,
        success: function (data) {
            if (data.status == true) {
                $('#img' + position)
                    .attr('src', $('#item' + idItem + ' img').attr('src'))
                    .parent()
                    .addClass('place-item-img')
                $('.active').removeClass('active')
                $(':checked').prop('checked', false)
                $('#galleryImages').modal('toggle')
            }
            if (data.status == false) {
                console.log(data)
            }
        }
    })
})

$('.ajax-delete-gallery').on('click', function () {
    let url = $('#GridGallery').data('href')
    let position = $('.ajax-gallery').data('position')
    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        type: 'POST',
        url: url,
        data: 'file_id=0&position=' + position,
        success: function (data) {
            if (data.status == true) {
                $('#img' + position)
                    .attr('src', '/img/add-default-img.svg')
                    .removeClass('place-item-img')

                $('.active').removeClass('active')
                $(':checked').prop('checked', false)
                $('#galleryImages').modal('toggle')
            }
            if (data.status == false) {
                console.log(data)
            }
        }
    })
})

$('.delete-alert').on('click', function (event) {
    event.preventDefault()
    const url = $(this).data('action')
    swal({
        title: 'Видалити?',
        text: 'Ви збираєтесь видалити запис!',
        icon: 'warning',
        buttons: true,
        dangerMode: true
    }).then(willDelete => {
        if (willDelete) {
            $.ajax({
                headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
                method: 'POST',
                url: url,
                success: function () {
                    swal('Запис видалено!', {
                        icon: 'success'
                    })
                    setTimeout(() => {
                        window.location.reload()
                    }, 500)
                }
            })
        } else {
            swal('Скасовано', 'Видалення запису скасовано', 'error')
        }
    })
})

$('.status-alert').on('change', function (event) {
    const oldStatus = !$(this).prop('checked')
    const $me = $(this)

    event.preventDefault()
    var status = 0
    console.log(oldStatus)
    if ($(this).is(':checked')) status = 1
    var url = $(this).data('href')
    swal({
        title: 'Змінити?',
        text: 'Ви намагаєтесесь змінити!',
        icon: 'warning',
        buttons: true,
        dangerMode: true
    }).then(function (willDelete) {
        if (willDelete) {
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                method: 'POST',
                url: url,
                data: 'status=' + status,
                success: function success() {
                    swal('Успішно змінено!', {
                        icon: 'success'
                    })
                }
            })
        } else {
            swal('Скасовано ', 'Зміну скасовано', 'error')
            $me.prop('checked', oldStatus)
        }
    })
})
