;(() => {
    const triggers = document.querySelectorAll('.js-trigger-resize')
    triggers.forEach(trigger => {
        if (trigger) {
            trigger.addEventListener('click', () => {
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'))
                }, 100)
            })
        }
    })
})()
