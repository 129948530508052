function getTextNodes(el) {
    let node
    const textNodes = []
    const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null)
    while ((node = walk.nextNode())) {
        textNodes.push(node)
    }
    return textNodes
}

String.prototype.indexOfQuote = function (start = 0) {
    const quotes = ['"', "'", '«', '»']
    const indices = []

    for (let i = 0; i < quotes.length; ++i) {
        const index = this.indexOf(quotes[i], start)
        if (index != -1) {
            indices.push(index)
        }
    }

    if (!indices.length) return -1
    if (indices.length == 1) return indices[0]

    let index = indices[0]
    for (let i = 1; i < indices.length; ++i) {
        if (indices[i] < index) {
            index = indices[i]
        }
    }

    return index
}

function quoteReplacement(editor, e) {
    const body = editor.getBody()
    const nodesCount = getTextNodes(body).length

    let shouldSetOpenQuote = true

    for (let i = 0; i < nodesCount; ++i) {
        let node = getTextNodes(body)[i]

        let text

        try {
            text = node.textContent
        } catch (e) {
            console.log(node)
            continue
        }

        let index = text.indexOfQuote(0)

        let selection = editor.getDoc().getSelection()

        while (index != -1) {
            selection.setBaseAndExtent(node, index, node, index + 1)

            let quote = shouldSetOpenQuote ? '«' : '»'
            shouldSetOpenQuote = !shouldSetOpenQuote

            editor.execCommand('mceReplaceContent', false, quote)

            node = getTextNodes(body)[i]
            text = node.textContent
            index = text.indexOfQuote(index + 1)
        }
    }
}

// For Articles
tinymce.init({
    selector: '#tiny-editor-news',
    height: 400,
    menubar: false,
    plugins: [
        'advlist autolink lists link image  print preview anchor',
        'searchreplace visualblocks fullscreen ',
        'insertdatetime media table paste hr code GigaQuote GigaPhotos GigaGallery GigaYotube GigaAnchor GigaContentTitle GigaShort GigaTelegram GigaInsta GigaTwitter',
        'GigaFacebookVideo GigaFacebookPost GigaNoFollow GigaDoFollow'
    ],
    toolbar:
        'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link code | GigaQuote GigaShort | GigaPhotos GigaGallery | GigaYotube GigaFacebookVideo GigaFacebookPost | GigaContentTitle GigaAnchor | GigaTelegram GigaInsta GigaTwitter | GigaNoFollow GigaDoFollow',

    toolbar_drawer: 'wrap',

    content_style: 'body { font-family: Arial,sans-serif; font-size:14px }',
    content_langs: [
        { title: 'English', code: 'en' },
        { title: 'Ukrainian', code: 'uk' },
        { title: 'Russian', code: 'ru' }
    ],
    entity_encoding: 'raw',
    language: 'ru',
    paste_as_text: true,
    contextmenu: false,

    file_picker_callback: function (callback, value, meta) {
        if (meta.filetype == 'image') {
            var input = document.getElementById('tinymce-photos')
            input.click()
            input.onchange = function () {
                const files = input.files
                const fileNames = []

                for (const key in files) {
                    if (files.hasOwnProperty(key)) {
                        const file = files[key]
                        fileNames.push(file.name)

                        const reader = new FileReader()

                        reader.onload = function (e) {
                            callback(fileNames.join(', '), {
                                alt: file.name
                            })
                        }

                        reader.readAsDataURL(file)
                    }
                }
            }
        }
    },
    setup: function (editor) {
        let shouldSetOpenQuote = true
        editor.on('keypress', function (e) {
            if (e.key === '"') {
                e.preventDefault()

                let quote = shouldSetOpenQuote ? '«' : '»'
                shouldSetOpenQuote = !shouldSetOpenQuote
                editor.execCommand('mceReplaceContent', false, quote)
                editor.selection.collapse()
            }
        })
        editor.on('input', function (e) { })
    }
})

// For Policy
tinymce.init({
    selector: '#tiny-editor-policy',
    height: 400,
    menubar: false,
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks fullscreen ',
        'insertdatetime media table paste hr code'
    ],
    toolbar:
        'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify',
    toolbar_drawer: 'wrap',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    content_langs: [
        { title: 'English', code: 'en' },
        { title: 'Ukrainian', code: 'ua' },
        { title: 'Russian', code: 'ru' }
    ],
    paste_as_text: true,
    contextmenu: false,
    setup: function (editor) {
        let shouldSetOpenQuote = true
        editor.on('keypress', function (e) {
            if (e.key === '"') {
                e.preventDefault()

                let quote = shouldSetOpenQuote ? '«' : '»'
                shouldSetOpenQuote = !shouldSetOpenQuote
                editor.execCommand('mceReplaceContent', false, quote)
                editor.selection.collapse()
            }
        })
        editor.on('input', function (e) { })
    }
})
