tinymce.PluginManager.add('GigaNoFollow', function (editor, url) {
    const openDialog = () => {
        return editor.windowManager.open({
            title: 'Зовнішнє посилання - NOFOLLOW',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'input',
                        name: 'link',
                        label: 'Посилання nofollow'
                    },
                    {
                        type: 'input',
                        name: 'name',
                        label: 'Назва'
                    },
                    {
                        type: 'input',
                        name: 'title',
                        label: 'Підсказка'
                    }
                ]
            },
            buttons: [
                {
                    type: 'cancel',
                    text: 'Відмінити'
                },
                {
                    type: 'submit',
                    text: 'Добавити',
                    primary: true
                }
            ],

            onSubmit(api) {
                const data = api.getData()
                const { link, name, title } = data
                if (link.length && name.length && title.length) {
                    editor.insertContent(`<a href="${link}" target="_blank" rel="nofollow" title="${title}">${name}</a>`)

                    api.close()
                }
            }
        })
    }

    editor.ui.registry.addButton('GigaNoFollow', {
        text: 'nofollow',
        onAction() {
            openDialog()
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaNoFollow',
                url: ''
            }
        }
    }
})

tinymce.PluginManager.add('GigaDoFollow', function (editor, url) {
    const openDialog = () => {
        return editor.windowManager.open({
            title: 'Зовнішнє посилання - DOFOLLOW',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'input',
                        name: 'link',
                        label: 'Посилання dofollow'
                    },
                    {
                        type: 'input',
                        name: 'name',
                        label: 'Назва'
                    },
                    {
                        type: 'input',
                        name: 'title',
                        label: 'Підсказка'
                    }
                ]
            },
            buttons: [
                {
                    type: 'cancel',
                    text: 'Відмінити'
                },
                {
                    type: 'submit',
                    text: 'Добавити',
                    primary: true
                }
            ],

            onSubmit(api) {
                const data = api.getData()
                const { link, name, title } = data
                if (link.length && name.length && title.length) {
                    editor.insertContent(`<a href="${link}" target="_blank" title="${title}">${name}</a>`)

                    api.close()
                }
            }
        })
    }

    editor.ui.registry.addButton('GigaDoFollow', {
        text: 'dofollow',
        onAction() {
            openDialog()
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaDoFollow',
                url: ''
            }
        }
    }
})



// http://example.com