tinymce.PluginManager.add('GigaAnchor', function (editor, url) {
    editor.ui.registry.addButton('GigaAnchor', {
        text: 'Пункт змісту',
        onAction() {
            editor.focus()

            const text = editor.selection.getContent({ format: 'html' })

            if (text && text.length > 0) {
                editor.execCommand('mceInsertContent', false, `[anchor]${text}</p>[/anchor]`)
            }
        }
    })

    return {
        getMetadata() {
            return {
                name: 'GigaAnchor',
                url: ''
            }
        }
    }
})
